
// import './main.js';
// import './style/tailwind.css';
// import './style/projects.css';
// import './projects.js';
// import './projects.js';

// 'use strict';

// import 'splitting/dist/splitting.css';
// import 'splitting/dist/splitting-cells.css';

// import barba from '@barba/core';
// import barbaCss from '@barba/css';
// import imagesLoaded from 'imagesloaded';
import LocomotiveScroll from 'locomotive-scroll';

// import Splitting from 'splitting';

// import Swup from 'swup';

// import {onSubmit, validateForm} from './subscribe_form.js';

// // Add event listener to form submission
// document.addEventListener('DOMContentLoaded', function() {
//   var form = document.getElementById('subscription-form');
//   var submitBtn = document.getElementById('submit-btn');

//   if (form && submitBtn) {
//     form.addEventListener('submit', function(event) {
//       event.preventDefault();  // Prevent the default form submission
//       if (validateForm()) {
//         onSubmit();     // Call onSubmit function if form is valid
//         form.submit();  // Submit the form
//       }
//     });
//   } else {
//     console.error('Form or submit button not found.');
//   }
// });



// // .......................

// // Fetch JSON data from a URL
// fetch(
//     'https://raw.githubusercontent.com/ttministries/ttministries/main/verse.json')
//     .then(response => response.json())  // Parse the JSON response
//     .then(jsonData => {
//       // Use the jsonData in your code
//       console.log(jsonData);
//     })
//     .catch(error => {
//       // Handle any errors that occur during the fetch
//       console.error('Error fetching JSON:', error);
//     });
// // import verseText from './verse.json' assert {type : 'json'};
// // console.log(verseText);
// // // Import the JSON data
// // // Import the JSON data
// // import jsonData from './verse.json';

// // Function to display the verse text and reference in HTML
// function displayJSONData(data) {
//   const verseDisplayElement = document.getElementById('verseDisplay');
//   const referenceDisplayElement =
//   document.getElementById('referenceDisplay');

//   // Create a <div> element for the verse text
//   const verseTextElement = document.createElement('div');
//   verseTextElement.textContent = data.verseText;

//   // Create a <div> element for the verse reference
//   const verseReferenceElement = document.createElement('div');
//   verseReferenceElement.textContent = data.verseReference;

//   // Append the verse text element to its display element
//   verseDisplayElement.appendChild(verseTextElement);

//   // Append the verse reference element to its display element
//   referenceDisplayElement.appendChild(verseReferenceElement);
// }

// // Call the function to display the JSON data
// displayJSONData(jsonData);

// Function to fetch JSON data from a URL
function fetchJSONData(url) {
  fetch(url)
      .then(response => response.json())  // Parse the JSON response
      .then(jsonData => {
        // Call the function to display the JSON data
        displayJSONData(jsonData);
      })
      .catch(error => {
        // Handle any errors that occur during the fetch
        console.error('Error fetching JSON:', error);
      });
}

// URL of the JSON file
const jsonURL =
    'https://raw.githubusercontent.com/ttministries/ttministries/main/verse.json';

// Call the function to fetch JSON data
fetchJSONData(jsonURL);

// Function to display the verse text and reference in HTML
function displayJSONData(data) {
  const verseDisplayElement = document.getElementById('verseDisplay');
  const referenceDisplayElement = document.getElementById('referenceDisplay');

  // Create a <div> element for the verse text
  const verseTextElement = document.createElement('div');
  verseTextElement.textContent = data.verseText;

  // Create a <div> element for the verse reference
  const verseReferenceElement = document.createElement('div');
  verseReferenceElement.textContent = data.verseReference;

  // Append the verse text element to its display element
  verseDisplayElement.appendChild(verseTextElement);

  // Append the verse reference element to its display element
  referenceDisplayElement.appendChild(verseReferenceElement);
}


// // Function to display the JSON data in HTML
// function displayJSONData(data) {
//   const jsonDisplayElement = document.getElementById('jsonDisplay');

//   // Create a <pre> element to display JSON with formatting
//   const preElement = document.createElement('pre');
//   preElement.textContent = JSON.stringify(data, null, 2);

//   // Append the <pre> element to the display element
//   jsonDisplayElement.appendChild(preElement);
// }

// // Call the function to display the JSON data
// displayJSONData(verseText);
// // Splitting();
// // console.clear();



const pageContainer = document.querySelector('.scrollContainer');


const locoScroll = new LocomotiveScroll({
  el: document.querySelector('.scrollContainer'),
  smooth: true,
  inertia: 0.8,
  smooth: true,
  lerp: 0.08,
  getDirection: true,
  smoothMobile: false,
  scrollFromAnywhere: false,
  mobile: {
    breakpoint: 0,
    smooth: false,
    getDirection: false,
  },
  tablet: {
    breakpoint: 0,
    smooth: true,
    getDirection: true,
  },
});

document.addEventListener('DOMContentLoaded', function() {
  const motiLink = document.getElementById('moti-link');

  if (motiLink) {
    motiLink.addEventListener('click', function(event) {
      event.preventDefault();  // Prevent default link behavior

      // Perform any custom actions if needed before navigating
      // For example, closing navigation menus, animating transitions, etc.

      // Navigate to the target page after custom actions
      window.location.href = event.target.href;
    });
  }
});

document.addEventListener('DOMContentLoaded', function() {
  const thumbnails = document.querySelectorAll('.video-thumbnail');
  const modal = document.getElementById('videoModal');
  const videoFrame = document.getElementById('videoFrame');
  const closeModal = document.querySelector('.close-modal');

  thumbnails.forEach(thumbnail => {
    thumbnail.addEventListener('click', function() {
      const videoSrc = this.getAttribute('data-video-src');
      videoFrame.setAttribute('src', videoSrc);
      modal.style.display = 'block';
    });
  });

  closeModal.addEventListener('click', function() {
    videoFrame.setAttribute('src', '');
    modal.style.display = 'none';
  });

  const playButtons = document.querySelectorAll('.play-button');
  playButtons.forEach(button => {
    button.addEventListener('click', function() {
      const videoSrc = this.getAttribute('data-video-src');
      videoFrame.setAttribute('src', videoSrc);
      modal.style.display = 'block';
    });
  });
});


const typedTextSpan = document.querySelector('.typed-text');
const textArray = ['Wisdom', 'Wonder', 'Wellbeing'];
const typingDelay = 100;
const erasingDelay = 50;
const newTextDelay = 2000;
let textArrayIndex = 0;
let charIndex = 0;
function type() {
  if (charIndex < textArray[textArrayIndex].length) {
    typedTextSpan.textContent += textArray[textArrayIndex].charAt(charIndex);
    charIndex++;
    setTimeout(type, typingDelay);
  } else
    setTimeout(erase, newTextDelay);
}
function erase() {
  if (charIndex > 0) {
    typedTextSpan.textContent =
        textArray[textArrayIndex].substring(0, charIndex - 1);
    charIndex--;
    setTimeout(erase, erasingDelay);
  } else {
    textArrayIndex++;
    if (textArrayIndex >= textArray.length) textArrayIndex = 0;
    setTimeout(type, typingDelay + 1100);
  }
}
document.addEventListener('DOMContentLoaded', function() {
  setTimeout(type, newTextDelay);
});



const toggleLinksBtn = document.getElementById('toggleLinksBtn');
const bottomLinks = document.querySelector('.bottom-links');
const closeNavBtn = document.getElementById('closeNavBtn');
const navLinks = document.querySelectorAll('.nav-link');

toggleLinksBtn.addEventListener('click', function() {
  bottomLinks.classList.toggle('hidden');
});

closeNavBtn.addEventListener('click', function() {
  bottomLinks.classList.add('hidden');
});

// Smooth scrolling for anchor links
navLinks.forEach(link => {
  link.addEventListener('click', function(e) {
    e.preventDefault();

    // const targetId = this.getAttribute("href");
    // document.querySelector(targetId).scrollIntoView({
    //   behavior: "smooth"
    // });

    // Close the fullscreen navigation after clicking a link
    bottomLinks.classList.add('hidden');
  });
});



// document.querySelectorAll('header a').forEach(anchor => {
//   anchor.addEventListener('click', function(e) {
//     e.preventDefault();
//     const targetId = this.getAttribute('href');
//     locoScroll.scrollTo(targetId);
//   });
// });

// document.querySelectorAll('div a').forEach(anchor => {
//   anchor.addEventListener('click', function(e) {
//     const targetId = this.getAttribute('href');

//     // Check if the target is the YouTube link
//     if (targetId === 'http://www.youtube.com/@thoothukudithootharaj97') {
//       return;  // Skip smooth scroll for the YouTube link
//     }

//     e.preventDefault();

//     // Check if Locomotive Scroll is initialized
//     if (typeof locoScroll !== 'undefined') {
//       locoScroll.scrollTo(targetId);
//     } else {
//       // Fallback to normal scroll behavior if Locomotive Scroll is not
//       // initialized
//       document.querySelector(targetId).scrollIntoView({behavior: 'smooth'});
//     }
//   });
// });

// const scroll = new LocomotiveScroll({
//   // Other options...
//   container: document.getElementById('youtube-link-container')
// });

// document.getElementById('mobnav a').forEach(anchor => {
//   anchor.addEventListener('click', function(e) {
//     e.preventDefault();
//     const targetId = this.getAttribute('href');
//     locoScroll.scrollTo(targetId);
//   });
// });


// const locoScroll = new LocomotiveScroll({
// 	el: document.querySelector(".scrollContainer"),
// 	smooth: true
// });



// each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync
// positioning)



locoScroll.on('scroll', ScrollTrigger.update);

// tell ScrollTrigger to use these proxy methods for the ".smooth-scroll"
// element since Locomotive Scroll is hijacking things
ScrollTrigger.scrollerProxy('.scrollContainer', {
  scrollTop(value) {
    return arguments.length ? locoScroll.scrollTo(value, 0, 0) :
                              locoScroll.scroll.instance.scroll.y;
  },  // we don't have to define a scrollLeft because we're only scrolling
      // vertically.
  getBoundingClientRect() {
    return {
      top: 0,
      left: 0,
      width: window.innerWidth,
      height: window.innerHeight
    };
  },
  // LocomotiveScroll handles things completely differently on mobile devices -
  // it doesn't even transform the container at all! So to get the correct
  // behavior and avoid jitters, we should pin things with position: fixed on
  // mobile. We sense it by checking to see if there's a transform applied to
  // the container (the LocomotiveScroll-controlled element).
  pinType: document.querySelector('.scrollContainer').style.transform ?
      'transform' :
      'fixed'
});


///////
/* ADD SKEW SECTION */

let proxy = {skew: 0},
    skewSetter = gsap.quickSetter('.skewElem', 'skewY', 'deg'),  // fast
    clamp =
        gsap.utils.clamp(-20, 20);  // don't let the skew go beyond 20 degrees.

ScrollTrigger.create({
  scroller: '.scrollContainer',
  trigger: '.root',

  onUpdate: (self) => {
    let skew = clamp(self.getVelocity() / -300);
    // only do something if the skew is MORE severe. Remember, we're always
    // tweening back to 0, so if the user slows their scrolling quickly, it's
    // more natural to just let the tween handle that smoothly rather than
    // jumping to the smaller skew.
    if (Math.abs(skew) > Math.abs(proxy.skew)) {
      proxy.skew = skew;
      gsap.to(proxy, {
        skew: 0,
        duration: 0.4,
        ease: 'power3',
        overwrite: true,
        onUpdate: () => skewSetter(proxy.skew)
      });
    }
  }
});

// // GSAP animation for fixed header
// var dhdr = gsap.timeline({defaults: {duration: 1}})

// dhdr.to('.deskheader', {
//   scrollTrigger: {
//     trigger: '.deskheader',
//     start: 'top top',
//     endTrigger: 'body',
//     end: 'bottom top',
//     pin: true,
//     pinSpacing: false
//   }
// });

///////////skew


var tl = gsap.timeline({defaults: {duration: 1}})

tl.fromTo('body, header', 1.8, {opacity: 0, backgroundColor: '#00000'}, {
    opacity: 1,
    backgroundColor: 'bg-gradient-to-r from-violet-800 to-black',
    ease: 'Power2.easeInOut'
  })
    .to('.htanim', {opacity: 1, y: -50, ease: 'Power2.easeInOut', stagger: .2})
    .fromTo('.lineee', {opacity: 0, scaleX: 0}, {opacity: 1, scaleX: 1}, '-=.5')
    .to('.herowrk', {opacity: 1, y: -20, ease: 'Power2.easeInOut'}, '-=.5')
    .fromTo(
        '.imgg', {
          opacity: 0,
          scaleX: 1.4,
          scaleY: 1.4,
          x: 150,
          skewX: 10,
          skewY: -9,
          ease: 'Power2.easeInOut'
        },
        {opacity: 1, skewX: 0, skewY: 0, x: -74, ease: 'Expo.easeOut'}, '-=.5')
    .fromTo('.imgg', {scale: 1.4}, {scale: 1, ease: 'Expo.easeOut'}, '-=.5')
    .fromTo(
        '.imggg', {opacity: 0, x: 150, ease: 'Power2.easeInOut'},
        {opacity: 1, x: -14, ease: 'Expo.easeOut'}, '-=.5')
// .fromTo(
//     '.header', {opacity: 0, y: -20, ease: 'Power5'},
//     {opacity: 1, y: 0, ease: 'Power5'}, '-=1.4')

// .fromTo(
//     '.marq', {opacity: 0, y: 420, ease: 'Power5'},
//     {opacity: 1, y: 0, ease: 'Power5'}, '-=2')


let wrtl =
    gsap.timeline({defaults: {}})
        .fromTo(
            '.workicnns', {opacity: 0},
            {opacity: 1, duration: 1, ease: 'Power2.easeOut'})
        .fromTo(
            '.toothuname', {opacity: 0, y: -120, duration: 5},
            {opacity: 1, y: 0, ease: 'Power2.easeOut'}, '-=0.5')
        .fromTo(
            '.toothumsg', {y: 180, opacity: 0, duration: 1.5},
            {y: 0, opacity: 1, ease: 'Power2.easeOut', stagger: 0.080}, '-=0.5')
        .fromTo(
            '.toothupara', {y: 180, opacity: 0, duration: 1.5},
            {y: 0, opacity: 1, ease: 'Power2.easeOut', stagger: 0.080}, '-=0.4')
        .fromTo(
            '.toothuabimg', {y: 180, opacity: 0, duration: 1.5},
            {y: 0, opacity: 1, ease: 'Power2.easeOut', stagger: 0.080}, '-=0.4')
        .fromTo(
            '.btnchl', {y: 180, opacity: 0, duration: 1.5},
            {y: 0, opacity: 1, ease: 'Power2.easeOut', stagger: 0.080}, '-=0.4')


ScrollTrigger.create({
  trigger: '.homepg',
  start: '70% 10%',
  end: '+=300',
  scroller: '.scrollContainer',
  animation: wrtl,
});


let wwre =
    gsap.timeline({defaults: {}})
        .fromTo(
            '.whoweare', {y: 180, opacity: 0},
            {y: 0, opacity: 1, ease: 'Power2.easeOut'})
        .fromTo(
            '.wwrtx1', {y: 180, opacity: 0, duration: 1.5},
            {y: 0, opacity: 1, ease: 'Power2.easeOut', stagger: 0.080}, '-=0.4')
        .fromTo(
            '.wwrtx2', {y: 180, opacity: 0, duration: 1.5},
            {y: 0, opacity: 1, ease: 'Power2.easeOut', stagger: 0.080}, '-=0.4')
        .fromTo(
            '.wwrbtn', {y: 180, opacity: 0, duration: 1.5},
            {y: 0, opacity: 1, ease: 'Power2.easeOut', stagger: 0.080}, '-=0.4')


ScrollTrigger.create({
  trigger: '.toothuname',
  start: '70% 10%',
  end: '+=300',
  scroller: '.scrollContainer',
  animation: wwre,
});

////////////////////////////////////

// let prtli =
//     gsap.timeline({defaults: {}})
//         .fromTo(
//             '.workicnns', {opacity: 0},
//             {opacity: 1, duration: 1, ease: 'Power2.easeOut'})
//         .fromTo(
//             '.toothuname', {opacity: 0, y: 20, duration: 5},
//             {opacity: 1, y: 0, ease: 'Power2.easeOut'}, '-=0.9')
//         .fromTo(
//             '.toothumsg', {y: 80, opacity: 0, duration: 1.5},
//             {y: 0, opacity: 1, ease: 'Power2.easeOut', stagger: 0.080},
//             '-=0.5')
//         .fromTo(
//             '.toothupara', {y: 80, opacity: 0, duration: 1.5},
//             {y: 0, opacity: 1, ease: 'Power2.easeOut', stagger: 0.080},
//             '-=0.3')
//         .fromTo(
//             '.toothuabimg', {y: 80, opacity: 0, duration: 1.5},
//             {y: 0, opacity: 1, ease: 'Power2.easeOut', stagger: 0.080},
//             '-=0.2')

// ScrollTrigger.create({
//   trigger: '.res',
//   start: '70% 10%',
//   end: '+=300',
//   scroller: '.scrollContainer',
//   animation: prtli
// })

///////////////////////////////////


let prtl =
    gsap.timeline({defaults: {}})
        .fromTo(
            '.madetoolsss', {opacity: 0},
            {opacity: 1, duration: 1, ease: 'Power2.easeOut'})
        .fromTo(
            '#work', {opacity: 0, duration: 5},
            {opacity: 1, ease: 'Power2.easeOut'}, '-=0.9')
        .fromTo(
            '.proimg', {y: 30, opacity: 0, duration: 1.5},
            {y: 0, opacity: 1, ease: 'Power2.easeOut', stagger: 0.080}, '-=0.5')

ScrollTrigger.create({
  trigger: '.wrkcont',
  start: '70% 10%',
  end: '+=300',
  scroller: '.scrollContainer',
  animation: prtl
})

// let wrkendtl = gsap.timeline({defaults: {}})
//                    .fromTo(
//                        'body, header', {backgroundColor: '#861F3E'},
//                        {backgroundColor: '#17171F', ease:
//                        'Power2.easeInOut'})

// ScrollTrigger.create({
//   trigger: '#wrk',
//   start: 'top 10%',
//   end: 'bottom 10%',
//   scrub: true,
//   scroller: '.scrollContainer',
//   animation: wrkendtl

// })

let prtlll = gsap.timeline({defaults: {duration: 1}})
                 //  .fromTo(
                 //      'body, header', {backgroundColor: '#17171F'},
                 //      {backgroundColor: '#17171F', ease: 'Power2.easeInOut'})
                 .fromTo(
                     '.proimg', {duration: 1, ease: 'slow.easeInOut'},
                     {rotate: 0, scale: 1, x: 0, y: 0, ease: 'slow.easeInOut'})

ScrollTrigger.create({
  trigger: '#proimmg',
  start: 'top 15%',
  end: '+=300',
  scrub: true,
  scroller: '.scrollContainer',
  animation: prtlll
})



// let expendtl = gsap.timeline({defaults: {}})
//                    .fromTo(
//                        'body, header', {backgroundColor: '#17171F'},
//                        {backgroundColor: '#17171F', ease:
//                        'Power2.easeInOut'})


// ScrollTrigger.create({
//   trigger: '.madetools',
//   start: '70% 10%',
//   // end: "+=300",
//   // toggleActions: "restart play reverse resume",
//   scroller: '.scrollContainer',
//   animation: expendtl
// })


ScrollTrigger.addEventListener('refresh', () => locoScroll.update());


ScrollTrigger.refresh();

document.addEventListener('DOMContentLoaded', function() {
  var form = document.getElementById('myForm');

  if (form) {
    form.addEventListener('submit', function(event) {
      event.preventDefault();  // Prevent the default form submission

      // Call the validation functions
      if (validateForm()) {
        form.submit();  // Submit the form if validation passes
      }
    });
  } else {
    console.error('Form not found.');
  }
});


// Validate Name
function validateName() {
  var name = document.getElementById('name').value.trim(); // Trim whitespace
  if (name.length === 0) {
    alert('Name can\'t be blank');
    return false;
  }
  if (!name.match(/^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,2}$/)) {
    alert('Please enter a valid name');
    return false;
  }
  return true;
}

// Validate Phone Number
function validatePhone() {
  var phone = document.getElementById('phone').value.trim(); // Trim whitespace
  if (phone.length === 0) {
    // alert('Phone number can\'t be blank');
    return true;
  }
  if (!phone.match(/^[0]?[789]\d{9}$/)) {
    alert('Please enter a valid phone number');
    return false;
  }
  return true;
}

// Validate Email
function validateEmail() {
  var email = document.getElementById('email').value.trim(); // Trim whitespace
  if (email.length === 0) {
    alert('Email can\'t be blank');
    return false;
  }
  if (!email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)) {
    alert('Please enter a valid email address');
    return false;
  }
  return true;
}

// Validate Form
function validateForm() {
  var submitted = false; // Initialize submitted variable
  if (!validateName() || !validatePhone() || !validateEmail()) {
    alert('Form not submitted'); // Validation Message
    return false;
  } else {
    submitted = true;

        // // Display thank you message
    document.getElementById('thankyou-message').style.display = 'block';

    // // // Change the ID and text of the button
    // // var submitButton = document.getElementById('submit-btn');
    // // submitButton.id = 'close-btn';  // Change to the desired new ID
    // // submitButton.textContent = 'CLOSE';

    // // Hide the form
    document.getElementById('subb').style.display = 'none';
    
    // // Disable the form inputs
    document.getElementById('namee').style.display = 'none';
    document.getElementById('phonee').style.display = 'none';
    document.getElementById('emaill').style.display = 'none';
    document.getElementById('submittt').style.display = 'none';
    // document.getElementById('clse').style.display = 'none';
    console.log('submitted');
    

    return true;
  }
}


function onSubmit() {
  document.getElementById('form').style.display = 'none';
  document.getElementById('thankyou-message').style.display = 'block';
}

var popupContainer = document.getElementById('popup-container');
var closeBtn = document.getElementById('close-btn');
var contactUsLinkDesktop = document.getElementById('contact-us-link-desktop');
var contactUsLinkMobile = document.getElementById('contact-us-link-mobile');

// Check if popup has already been shown
if (!sessionStorage.getItem('popupShown')) {
  // Show popup if it hasn't been shown
  window.addEventListener('load', function() {
    setTimeout(function() {
      popupContainer.style.display = 'block';
      popupContainer.classList.add('show');
    }, 5000);  // 5000 milliseconds = 5 seconds
  });
} else {
  // Hide popup if it has already been shown
  popupContainer.style.display = 'none';
}

// Add event listener to close button
closeBtn.addEventListener('click', function() {
  popupContainer.classList.add('hide');
  setTimeout(function() {
    popupContainer.style.display = 'none';
    popupContainer.classList.remove('hide', 'show');
  }, 500);  // Wait for animation to finish before removing popup from DOM

  // Set popupShown flag in session storage
  sessionStorage.setItem('popupShown', true);
});

// Function to show popup
function showPopup() {
  popupContainer.style.display = 'block';
  popupContainer.classList.add('show');

  // Set popupShown flag in session storage
  sessionStorage.setItem('popupShown', true);
}

// Add event listeners to contact us links
contactUsLinkDesktop.addEventListener('click', function(event) {
  event.preventDefault();  // Prevent default link behavior
  showPopup();             // Call showPopup function
});

contactUsLinkMobile.addEventListener('click', function(event) {
  event.preventDefault();  // Prevent default link behavior
  showPopup();             // Call showPopup function
});
